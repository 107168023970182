import React from 'react'

import Layout from "../components/layout"
import Bio from "../components/bio"
import SEO from "../components/seo"

const about = () => {
    return (
        <Layout page='4'>
            <SEO title='About'></SEO>
                <h1>About</h1>
                <Bio></Bio>
        </Layout>
    );
};

export default about